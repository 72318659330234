export function rgba2Rgb(colorCssString) {
  const isRGBA = function (colorCssString) {
    return colorCssString.match(/rgba/gi)
  }

  if (!isRGBA(colorCssString)) return colorCssString

  let colorArray = colorCssString.match(/\d+\.*\d*/g)
  // console.log(colorArray);
  // console.log(`colorArray: ${colorArray}`);
  // let rgb = colorCssString.match(/\d+\.*\d+/g)
  // console.log(`rgb: ${rgb}`);

  let Source = {}
  Source.R = colorArray.shift() / 255
  Source.G = colorArray.shift() / 255
  Source.B = colorArray.shift() / 255
  Source.A = colorArray.shift() / 1
  // console.log(colorArray);
  // console.log(Source);

  let BGColor = {
    R: 1,
    G: 1,
    B: 1,
  }

  //    Credits to:
  //    https://stackoverflow.com/questions/2049230/convert-rgba-color-to-rgb
  let Target = {}
  Target.R = parseInt(255 * ((1 - Source.A) * BGColor.R + Source.A * Source.R))
  Target.G = parseInt(255 * ((1 - Source.A) * BGColor.G + Source.A * Source.G))
  Target.B = parseInt(255 * ((1 - Source.A) * BGColor.B + Source.A * Source.B))

  const rgbColor = `rgb(${Target.R}, ${Target.G}, ${Target.B})`

  return rgbColor
}

export function hex2Rgba(hexVal) {
  if (hexVal.indexOf("#") > -1) {
    hexVal = hexVal.substr(1)
  }

  // turn 3-HEX to 6-HEX
  if (hexVal.length === 3) {
    hexVal =
      hexVal[0] + hexVal[0] + hexVal[1] + hexVal[1] + hexVal[2] + hexVal[2]
  }

  // extracting the hex values for RGB
  let red = hexVal.substr(0, 2),
    green = hexVal.substr(2, 2),
    blue = hexVal.substr(4, 2),
    alpha = hexVal.substr(6, 2)

  // converting in decimal values
  let red10 = parseInt(red, 16),
    green10 = parseInt(green, 16),
    blue10 = parseInt(blue, 16),
    alpha10 = parseInt(alpha, 16)

  let alphaMapped = mapValue(alpha10, 0, 255, 0, 1).toFixed(2)
  // stitching it together
  let rgb = red10 + "," + green10 + "," + blue10 + "," + alphaMapped

  // the final rgba CSS ouptut
  let rgba = "rgba(" + rgb + ")"
  return rgba
}

export const mapValue = (value, x1, y1, x2, y2) =>
  ((value - x1) * (y2 - x2)) / (y1 - x1) + x2

export const cleanPath = path => path.replace(/[\/\\]/g, "")
