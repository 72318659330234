import * as React from "react"
import { motion } from "framer-motion"

const button = {
  rest: { scale: 1 },
  hover: { scale: 1.1 },
  pressed: { scale: 0.95 },
}
const arrow = {
  rest: { rotate: 0 },
  hover: { rotate: 360, transition: { duration: 0.4 } },
}

const AnimatedScrew = ({ onClick, children }) => {
  return (
    <motion.button
      className="p-5 md:p-8 lg:p-10 3xl:p-24 focus:outline-none flex flex-row justify-center items-center cursor-pointer"
      onClick={onClick}
      variants={button}
      initial="rest"
      whileHover="hover"
      whileTap="pressed"
    >
      <motion.svg
        className="fill-current"
        xmlns="http://www.w3.org/2000/svg"
        variants={arrow}
        width="16"
        height="16"
        style={{ originX: "50%", originY: "50%" }}
      >
        {children}
      </motion.svg>
    </motion.button>
  )
}

export default AnimatedScrew
