import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import NavigationList from "./NavigationList"
import AnimatedScrew from "./AnimatedScrew"
import { menuMotion } from "./framerAnimations"
import useKeyPress from "../utils/useKeyPress"

const duration = 0.5

const variants = menuMotion(duration)

const Header = ({ color, isArchivePage }) => {
  const [isExpanded, toggleExpansion] = useState(false)
  const keyboardInputEsc = useKeyPress("Escape")

  useEffect(() => {
    if (keyboardInputEsc) {
      console.log("escape key")
      toggleExpansion(false)
    }
  }, [keyboardInputEsc])
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <header
      style={{ color: color, transition: "color 4s ease" }}
      className={`sticky top-0 z-120 mx-auto my-0 w-full flex items-center font-semibold tracking-widest text-xs lg:text-sm  ${
        isArchivePage ? `${color.indexOf("f") < 1 ? "invert" : null}` : null
      }

      `}
    >
      <div className="w-1/6 md:w-2/6">
        <AnimatedScrew onClick={() => toggleExpansion(!isExpanded)}>
          <path d="M8 3.2C9.22937 3.2 10.3508 3.66217 11.2 4.42223C12.182 5.30114 12.8 6.5784 12.8 8C12.8 9.4216 12.182 10.6989 11.2 11.5778C10.3508 12.3378 9.22937 12.8 8 12.8L8 3.2Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 7.47056e-08 12.4183 4.60964e-07 8C8.47222e-07 3.58172 3.58172 -1.32406e-06 8 -9.37801e-07C12.4183 -5.51543e-07 16 3.58172 16 8ZM8 1.6C11.5346 1.6 14.4 4.46538 14.4 8C14.4 11.5346 11.5346 14.4 8 14.4C4.46538 14.4 1.6 11.5346 1.6 8C1.6 4.46538 4.46538 1.6 8 1.6Z"
          />
        </AnimatedScrew>
      </div>
      <div className="w-4/6 md:w-2/6 text-center">
        <Link
          to="/"
          className="p-5 md:p-8 lg:p-10 3xl:p-24 uppercase duration-300 ease-in-out hover:opacity-50"
        >
          {data.site.siteMetadata.title}
        </Link>
      </div>
      <div className="w-1/6 md:w-2/6 inline-flex justify-end">
        <Link
          to="/info"
          className="p-5 md:p-8 lg:p-10 3xl:p-24 duration-300 ease-in-out hover:opacity-50 uppercase"
        >
          Info
        </Link>
      </div>
      <AnimatePresence>
        <motion.nav
          style={{
            width: "100%",
            position: "absolute",
            top: 0,
          }}
          initial={"exit"}
          animate={isExpanded ? "open" : "closed"}
          variants={variants}
          exit={"exit"}
          key={data.site.siteMetadata.title}
        >
          <aside
            className={`flex z-140 w-full fixed h-screen top-0 left-0 transform transition-transform duration-300 ease-in-out
              bg-brand-black flex-col justify-around
            `}
          >
            <div className="absolute top-0 flex items-center text-brand-white">
              <AnimatedScrew onClick={() => toggleExpansion(!isExpanded)}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 3.13124e-07 12.4183 6.99382e-07 8C1.08564e-06 3.58172 3.58172 -1.08564e-06 8 -6.99382e-07C12.4183 -3.13124e-07 16 3.58172 16 8ZM8 1.6C11.5346 1.6 14.4 4.46538 14.4 8C14.4 11.5346 11.5346 14.4 8 14.4C4.46538 14.4 1.6 11.5346 1.6 8C1.6 4.46538 4.46538 1.6 8 1.6Z"
                />
              </AnimatedScrew>
            </div>
            <NavigationList pageFooterInView={isExpanded} />
          </aside>
        </motion.nav>
      </AnimatePresence>
    </header>
  )
}

export default Header
