import React, { useState, useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import NavigationList from "./NavigationList"
import { useScrollPosition } from "../utils/useScrollPosition.js"
import { useBbox } from "../utils/useBbox"
import { mapValue } from "../utils"

const PageFooter = ({ pageFooterInView, contentHeight }) => {
  const data = useStaticQuery(graphql`
    query {
      currentBuildDate {
        currentDate
      }
      site {
        siteMetadata {
          instagram
          title
        }
      }
    }
  `)
  const [isInView, setIsInView] = useState(pageFooterInView)
  const [footerBodyBox, footerBodyRef] = useBbox(isInView)
  const [liAlpha, setLiAlpha] = useState(0)
  // const [bgAlpha, setBgAlpha] = useState(0.6)
  const [thisScrollPos, setThisScrollPos] = useState(0)

  useEffect(() => {
    if (isInView !== pageFooterInView) {
      if (thisScrollPos > contentHeight) {
        setIsInView(true)
      } else if (thisScrollPos > contentHeight && !pageFooterInView) {
        setIsInView(true)
      } else {
        setIsInView(pageFooterInView)
      }
    }
  }, [isInView, pageFooterInView, contentHeight, thisScrollPos])

  useScrollPosition(({ prevPos, currPos }) => {
    if (pageFooterInView) {
      let curYPos = currPos.y * -1
      let yPos = contentHeight - curYPos
      let footerHeight = footerBodyBox.height
      let thisLiAlpha = mapValue(yPos, 0, footerHeight, 1, 0).toFixed(2)
      // let thisBgAlpha = mapValue(yPos, 0, footerHeight, 0.7, 1).toFixed(2)
      setLiAlpha(thisLiAlpha)
      // setBgAlpha(thisBgAlpha)
      setThisScrollPos(curYPos)
    }
  })

  return (
    <footer
      ref={footerBodyRef}
      className="h-screen bg-brand-black text-brand-offwhite text-center flex flex-col justify-between pageFooter"
      style={{
        position: "sticky",
        bottom: 0,
        zIndex: 1,
      }}
    >
      <header className="z-120 mx-auto my-0 w-full flex items-center font-semibold tracking-widest uppercase text-xs lg:text-sm">
        <div className="w-1/6 md:w-2/6">
          <div className="p-5 md:p-8 lg:p-10 3xl:p-24 flex items-center focus:outline-none opacity-50">
            <svg
              className="h-3 w-3 sm:h-4 sm:w-4 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 4C11.5367 4 12.9385 4.57771 14 5.52779C15.2275 6.62643 16 8.223 16 10C16 11.777 15.2275 13.3736 14 14.4722C12.9385 15.4223 11.5367 16 10 16L10 4Z" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 3.91405e-07 15.5228 8.74228e-07 10C1.35705e-06 4.47715 4.47715 -1.35705e-06 10 -8.74228e-07C15.5228 -3.91405e-07 20 4.47715 20 10ZM10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2Z"
              />
            </svg>
          </div>
        </div>

        <div className="w-4/6 md:w-2/6 text-center">
          <Link
            to="/"
            className="p-5 md:p-8 lg:p-10 3xl:p-24 duration-300 ease-in-out hover:opacity-50"
          >
            {data.site.siteMetadata.title}
          </Link>
        </div>

        <div className="w-1/6 md:w-2/6 inline-flex justify-end">
          <Link
            to="/info"
            className="p-5 md:p-8 lg:p-10 3xl:p-24 duration-300 ease-in-out hover:opacity-50"
          >
            Info
          </Link>
        </div>
      </header>
      <NavigationList
        style={{ opacity: liAlpha, transition: "opacity 0.1s ease" }}
        pageFooterInView={isInView}
      />
      <section
        style={{ opacity: liAlpha, transition: "opacity 0.1s ease" }}
        className="container max-w-screen-xl font-sans p-5 sm:p-8 text-xs lg:text-sm"
      >
        <p>
          &copy;{data.currentBuildDate.currentDate}{" "}
          {data.site.siteMetadata.title} | Legal |{" "}
          <a
            className="transition duration-300 ease-in-out hover:opacity-50"
            href={data.site.siteMetadata.instagram}
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
        </p>
      </section>
    </footer>
  )
}

export default PageFooter
