import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useBbox } from "../utils/useBbox"

import Header from "./Header"
import PageFooter from "./PageFooter"

const Layout = props => {
  const { path, children } = props
  const [isProjectPage, setIsProjectPage] = useState()
  const [isArchivePage, setIsArchivePage] = useState()
  const [textColor, setTextColor] = useState("#000")
  const [backgroundColor, setBackgroundColor] = useState("#fff")
  const [pageFooterInView, setPageFooterInView] = useState()
  const [contentBodyBox, contentBodyRef] = useBbox(true)
  const [contentHeight, setContentHeight] = useState()

  useEffect(() => {
    let temp = path.indexOf("projects") > 0
    if (path.indexOf("projects") > 0) {
      temp = path.indexOf("projects") > 0
      setIsProjectPage(temp)
      setIsArchivePage(false)
    }
    if (path.indexOf("info") > 0) {
      temp = path.indexOf("info") > 0
      setIsProjectPage(false)
      setIsArchivePage(temp)
    }
    if (path.indexOf("archive") > 0) {
      temp = path.indexOf("archive") > 0
      setIsProjectPage(false)
      setIsArchivePage(temp)
    }
  }, [path])

  const handleNavigateToProject = link => {
    navigate(link)
  }

  const inViewColorHandler = colors => {
    if (backgroundColor !== colors.backgroundColor) {
      setTextColor(colors.textColor)
      setBackgroundColor(colors.backgroundColor)
    }
  }

  const handlePageFooterFade = isInView => {
    if (isInView !== pageFooterInView) {
      setContentHeight(contentBodyBox.height)
      setPageFooterInView(isInView)
    }
  }

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {
      handleNavigateToProject: handleNavigateToProject,
      inViewColorHandler: inViewColorHandler,
      bgColor: backgroundColor,
      handlePageFooterFade: handlePageFooterFade,
    })
  )

  useEffect(() => {
    if (isProjectPage) {
      setBackgroundColor("#ffffff")
      setTextColor("#000000")
    }
    if (isArchivePage) {
      setBackgroundColor("#000000")
      setTextColor("#ffffff")
    }
  }, [isProjectPage, isArchivePage])

  return (
    <>
      <div
        ref={contentBodyRef}
        style={{
          backgroundColor: backgroundColor,
          transition:
            !isProjectPage || isArchivePage
              ? "background 2s ease"
              : "background 4s ease",
          position: "relative",
          zIndex: 40,
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            position: "relative",
            zIndex: 300,
            transition:
              !isProjectPage || isArchivePage
                ? "background 2s ease"
                : "background 4s ease",
          }}
        >
          {!isProjectPage || isArchivePage ? (
            <Header color={textColor} isArchivePage={isArchivePage} />
          ) : null}
          {childrenWithProps}
        </div>
      </div>
      {!isProjectPage && !isArchivePage ? (
        <PageFooter
          pageFooterInView={pageFooterInView}
          contentHeight={contentHeight}
        />
      ) : null}
    </>
  )
}

export default Layout
