import { useState } from "react"
import { useRef } from "react"
import { useEffect } from "react"

export const useBbox = bool => {
  const ref = useRef()
  const [bbox, setBbox] = useState({})
  // console.log("from useBox",bool);
  const set = () =>
    setBbox(ref && ref.current ? ref.current.getBoundingClientRect() : {})

  useEffect(() => {
    set()
  }, [bool])

  useEffect(() => {
    set()
    window.addEventListener("resize", set)
    return () => window.removeEventListener("resize", set)
  }, [])

  // window.addEventListener("load", set)

  // return () => window.removeEventListener("load", set)
  useEffect(() => {
    set()
    window.addEventListener("load", set)
    return () => window.removeEventListener("load", set)
  }, [])

  return [bbox, ref]
}
