export const menuMotion = duration => ({
  open: {
    opacity: 1,
    x: 0,
    transition: {
      duration: duration,
      delay: 0,
      when: "afterChildren",
    },
  },
  closed: {
    opacity: 1,
    x: "-100vw",
    transition: {
      duration: duration,
      delay: 0,
      when: "afterChildren",
    },
  },
  exit: {
    x: "-100vw",
    when: "afterChildren",
  },
})

export const contentMotion = duration => ({
  initial: {
    opacity: 0,
    transition: {
      duration: duration / 2,
      delay: 0,
      when: "beforeChildren",
    },
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration / 2,
      when: "afterChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: duration / 2,
      when: "beforeChildren",
    },
  },
})

export const footerMotion = duration => ({
  initial: {
    opacity: 0,
    transition: {
      duration: duration / 2,
      delay: duration,
      when: "afterChildren",
    },
  },
  enter: {
    opacity: 0,
    transition: {
      duration: duration,
      delay: duration,
      when: "afterChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: duration,
      delay: duration,
      when: "afterChildren",
    },
  },
})

export const pageFooterContainerMotion = duration => ({
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: duration,
    },
  },
})

export const pageFooterItemMotion = {
  hidden: { y: -4, opacity: 0 },
  show: {
    y: 0,
    opacity: 0.9,
    transition: {
      duration: 1,
    },
  },
}
