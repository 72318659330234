import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import "./src/styles/fonts.scss"
import "./src/styles/main.scss"
import "./src/styles/carousel.scss"
import "./src/styles/tailwind.scss"
import "./src/styles/markdown.scss"

import Layout from "./src/components/Layout"

import { contentMotion } from "./src/components/framerAnimations"

const duration = 1 // duration of the animations in seconds
const myContentMotion = contentMotion(duration)

// const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;
// renderMethod(
//   <BrowserRouter>
//     <RoutersController data={data} routes={routes} />
//   </BrowserRouter>,
//   document.getElementById('root')
// );

export const wrapPageElement = ({ element, props }) => {
  return (
    <AnimatePresence>
      <motion.div
        key={props.path}
        variants={myContentMotion}
        initial={"initial"}
        animate="enter"
        exit="exit"
      >
        <Layout {...props}>{element}</Layout>
      </motion.div>
    </AnimatePresence>
  )
}

export const shouldUpdateScroll = ({ routerProps, getSavedScrollPosition }) => {
  // console.log(routerProps, document.getElementById(routerProps.location.hash.substring(1)),getSavedScrollPosition(routerProps.location));
  if (routerProps.location.pathname.indexOf("project") <= 0) {
    let article = document.getElementById(
      routerProps.location.hash.substring(1)
    )
    let scrollToPostion = 0
    if (article) {
      let articleBB = article.getBoundingClientRect()
      let articleHeight = articleBB.height
      let articleOffsetTop = articleBB.top
      const scrollToPos = offsetTop => {
        let temp
        if (offsetTop > 0) {
          temp = offsetTop - (window.innerHeight - articleHeight) / 2
          return temp
        } else {
          temp = offsetTop
          return temp
        }
      }
      scrollToPostion = scrollToPos(articleOffsetTop)
    }
    // console.log("not Project we scroll to position:", scrollToPostion)
    window.scrollTo(0, scrollToPostion)
    return false
  } else {
    // console.log("we came form index to project?", routerProps.location)
    return false
  }
}
